<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div class="col-7 p-0 align-self-center font-weight-bold d-flex align-items-center w-reset mb-sm-2">
        <h5 class="d-inline mr-2 font-weight-bold">
          {{ $t("message.patients") }}
        </h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-input size="mini" :placeholder="$t('message.search')" prefix-icon="el-icon-search"
            v-model="filterForm.search" clearable></el-input>
        </div>
        <div>
          <el-date-picker v-model="filterForm.period" type="daterange" :start-placeholder="$t('message.start_period')"
                          :end-placeholder="$t('message.end_period')">
          </el-date-picker>
        </div>
      </div>
      <div class="col-5 align-items-center align-self-center text-right pr-0 d-flex justify-content-end w-reset">
        <el-button size="mini" v-can="'patients.create'" @click="drawerCreate = true">
          <i class="el-icon-circle-plus-outline"></i> {{ $t("message.create") }}
        </el-button>
        <export-excel v-can="'patients.excel'" class="btn excel_btn" :data="list" :fields="excel_fields"
          @fetch="controlExcelData()" worksheet="Пациенты " name="Пациенты.xls">
          <el-button size="mini" icon="el-icon-document-delete">
            Excel
          </el-button>
        </export-excel>
        <crm-column-settings :columns="columns" :modelName="'patients'" @c-change="updateColumn"></crm-column-settings>
      </div>
    </div>
    <table class="table table-bordered table-hover" v-loading="loadingData">
      <crm-pagination :pagination="pagination" @c-change="updatePagination"></crm-pagination>
      <thead>
        <tr>
          <crm-th :column="columns.id" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.surname" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.first_name" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.patronymic" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.phone" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.born_date" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.social_id" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.code" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.gender" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.organization_id" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.document_type_id" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.series_and_number" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.drive_license" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.passport_number" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.date_of_issue" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.authority" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.state_id" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.city_id" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.region" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.street" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.area" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.home" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.flat" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :sort="sort" :column="columns.updated_at" @c-change="updateSort"></crm-th>
          <crm-th :sort="sort" :column="columns.created_at" @c-change="updateSort"></crm-th>
          <crm-th :sort="sort" :column="columns.settings" @c-change="updateSort"></crm-th>
        </tr>

        <tr>
          <th v-if="columns.id.show">
            <el-input clearable size="mini" class="id_input" v-model="filterForm.id"
              :placeholder="columns.id.title"></el-input>
          </th>
          <th v-if="columns.surname.show">
            <el-input size="mini" v-model="filterForm.surname" :placeholder="columns.surname.title" clearable>
            </el-input>
          </th>
          <th v-if="columns.first_name.show">
            <el-input size="mini" v-model="filterForm.first_name" :placeholder="columns.first_name.title" clearable>
            </el-input>
          </th>
          <th v-if="columns.patronymic.show">
            <el-input size="mini" v-model="filterForm.patronymic" :placeholder="columns.patronymic.title" clearable>
            </el-input>
          </th>
          <th v-if="columns.phone.show">
            <el-input size="mini" v-model="filterForm.phone" :placeholder="columns.phone.title" clearable>
            </el-input>
          </th>
          <th v-if="columns.born_date.show">
            <el-date-picker v-model="filterForm.born_date" :placeholder="columns.born_date.title" size="mini"
              :format="'dd.MM.yyyy'" :value-format="'dd.MM.yyyy'">
            </el-date-picker>
          </th>
          <th v-if="columns.social_id.show">
            <el-select filterable clearable :placeholder="columns.social_id.title" size="mini"
              v-model="filterForm.social_id">
              <el-option v-for="item in socials" :key="item.name + item.id" :label="item.name"
                :value="item.id"></el-option>
            </el-select>
          </th>
          <th v-if="columns.code.show">
            <el-input size="mini" v-model="filterForm.code" :placeholder="columns.code.title" clearable>
            </el-input>
          </th>
          <th v-if="columns.gender.show">
            <el-select filterable clearable :placeholder="columns.gender.title" size="mini" v-model="filterForm.gender">
              <el-option :label="$t('message.male')" value="man"></el-option>
              <el-option :label="$t('message.female')" value="woman"></el-option>
            </el-select>
          </th>
          <th v-if="columns.organization_id.show">
            <el-select filterable clearable :placeholder="columns.organization_id.title" size="mini"
              v-model="filterForm.organization_id">
              <el-option v-for="item in organizations" :key="item.company_name + item.id" :label="item.company_name"
                :value="item.id">
              </el-option>
            </el-select>
          </th>

          <th v-if="columns.document_type_id.show">
            <el-select filterable clearable :placeholder="columns.document_type_id.title" size="mini"
              v-model="filterForm.document_type_id">
              <el-option v-for="item in DocumentTypes" :key="item.name + item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </th>
          <th v-if="columns.series_and_number.show">
            <el-input size="mini" v-model="filterForm.series_and_number" :placeholder="columns.series_and_number.title"
              clearable></el-input>
          </th>
          <th v-if="columns.drive_license.show">
            <el-input size="mini" v-model="filterForm.drive_license" :placeholder="columns.drive_license.title"
              clearable></el-input>
          </th>
          <th v-if="columns.passport_number.show">
            <el-input size="mini" v-model="filterForm.passport_number" :placeholder="columns.passport_number.title"
              clearable></el-input>
          </th>
          <th v-if="columns.date_of_issue.show">
            <el-date-picker :format="'dd.MM.yyyy'" :value-format="'dd.MM.yyyy'" v-model="filterForm.date_of_issue"
              :placeholder="columns.date_of_issue.title" size="mini">
            </el-date-picker>
          </th>
          <th v-if="columns.authority.show">
            <el-input size="mini" v-model="filterForm.authority" :placeholder="columns.authority.title"
              clearable></el-input>
          </th>
          <th v-if="columns.state_id.show">
            <el-select filterable clearable :placeholder="columns.state_id.title" size="mini"
              v-model="filterForm.state_id">
              <el-option v-for="item in states" :key="item.name + item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </th>
          <th v-if="columns.city_id.show">
            <el-select filterable clearable :placeholder="columns.city_id.title" size="mini" v-model="filterForm.city_id">
              <el-option v-for="item in cities" :key="item.name + item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </th>
          <th v-if="columns.region.show">
            <el-select filterable clearable :placeholder="columns.region.title" size="mini" v-model="filterForm.region">
              <el-option v-for="item in regions" :key="item.name + item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </th>
          <th v-if="columns.street.show">
            <el-input size="mini" v-model="filterForm.street" :placeholder="columns.street.title" clearable></el-input>
          </th>
          <th v-if="columns.area.show">
            <el-input size="mini" v-model="filterForm.area" :placeholder="columns.area.title" clearable></el-input>
          </th>
          <th v-if="columns.home.show">
            <el-input size="mini" v-model="filterForm.home" :placeholder="columns.home.title" clearable></el-input>
          </th>
          <th v-if="columns.flat.show">
            <el-input size="mini" v-model="filterForm.flat" :placeholder="columns.flat.title" clearable></el-input>
          </th>
          <th v-if="columns.updated_at.show">
            <el-date-picker :format="'dd.MM.yyyy'" :value-format="'dd.MM.yyyy'" v-model="filterForm.updated_at"
              :placeholder="columns.updated_at.title" size="mini">
            </el-date-picker>
          </th>
          <th v-if="columns.created_at.show">
            <el-date-picker :format="'dd.MM.yyyy'" :value-format="'dd.MM.yyyy'" v-model="filterForm.created_at"
              :placeholder="columns.created_at.title" size="mini">
            </el-date-picker>
          </th>
          <th v-if="columns.settings.show"></th>
        </tr>
      </thead>
      <transition-group name="flip-list" tag="tbody">
        <tr v-for="patient in list" :key="patient.id">
          <td v-if="columns.id.show">{{ patient.id }}</td>
          <td v-if="columns.surname.show">
            {{ patient.surname | uppercaseFirst }}
          </td>
          <td v-if="columns.first_name.show">
            {{ patient.first_name | uppercaseFirst }}
          </td>
          <td v-if="columns.patronymic.show">
            {{ patient ? patient.patronymic : "" }}
          </td>
          <td v-if="columns.phone.show">{{ patient.phone }}</td>
          <td v-if="columns.born_date.show">{{ patient.born_date }}</td>
          <td v-if="columns.social_id.show">
            {{ patient.social ? patient.social.name : "" }}
          </td>
          <td v-if="columns.code.show">{{ patient.code }}</td>
          <td v-if="columns.gender.show">
            <template v-if="patient.gender === 'man'">{{
                $t("message.male")
              }}</template>
            <template v-else>{{ $t("message.female") }}</template>
          </td>
          <td v-if="columns.organization_id.show">
            {{ patient.organization ? patient.organization.company_name : "" }}
          </td>

          <td v-if="columns.document_type_id.show">
            {{ patient.documentType ? patient.documentType.name : "" }}
          </td>

          <td v-if="columns.series_and_number.show">
            {{ patient.series_and_number }}
          </td>

          <td v-if="columns.drive_license.show">
            {{ patient.drive_license }}
          </td>

          <td v-if="columns.passport_number.show">
            {{ patient.passport_number }}
          </td>

          <td v-if="columns.date_of_issue.show">
            {{ patient ? patient.date_of_issue : "" }}
          </td>
          <td v-if="columns.authority.show">{{ patient.authority }}</td>

          <td v-if="columns.state_id.show">
            {{ patient.state ? patient.state.name : "" }}
          </td>

          <td v-if="columns.city_id.show">
            {{ patient.city ? patient.city.name : "" }}
          </td>
          <td v-if="columns.region.show">
            {{ patient.region[0] ? patient.region[0].name : "" }}
          </td>
          <td v-if="columns.street.show">{{ patient.street }}</td>
          <td v-if="columns.area.show">{{ patient.area }}</td>
          <td v-if="columns.home.show">{{ patient.home }}</td>
          <td v-if="columns.flat.show">{{ patient.flat }}</td>
          <td v-if="columns.updated_at.show">
            {{ patient.updated_at }}
          </td>

          <td v-if="columns.created_at.show">
            {{ patient.created_at }}
          </td>

          <td v-if="columns.settings.show" class="">
            <div class="d-flex settings-td">
              <el-button round @click="showConclusion(patient)" size="mini" type="success" icon="el-icon-view"
                class="mr-2">
              </el-button>
              <crm-setting-dropdown :model="patient" name="patients" :actions="actions" @edit="edit" @delete="destroy">
              </crm-setting-dropdown>
            </div>
          </td>
        </tr>
      </transition-group>
    </table>


    <el-drawer :visible.sync="drawerCreate" size="60%" :drawer="drawerCreate" :wrapperClosable="false"
      @close="reloadIfChanged">
      <div>
        <crm-create :reloadModel="reopenUpdate" :reopened="drawerCreate"
          @c-close="reloadIfChanged('drawerCreate')"></crm-create>
      </div>
    </el-drawer>
    <el-drawer :visible.sync="drawerUpdate" size="60%" :drawer="drawerUpdate" :wrapperClosable="false"
      @open="reopenUpdate = true" @close="reloadIfChanged('reopenUpdate')" @closed="emptyModel" class="mytab_IdP">
      <div v-if="update_drawer">
        <crm-update :reloadModel="reopenUpdate" @open="reopenUpdate = true" @c-close="closeDrawer"
          @after-close="close_Drawer"></crm-update>
      </div>
    </el-drawer>
    <el-drawer title="Изменить тег" size="70%" :wrapperClosable="false" :visible.sync="drawer.conclusionShow.status"
      :ref="drawer.conclusionShow.name" @opened="drawerOpened(drawer.conclusionShow.component)"
      @closed="drawerClosed(drawer.conclusionShow.component)">
      <div>
        <crm-all-show :selected_patient="selected_patient" :reloadModel="reopenShow"
          :ref="drawer.conclusionShow.component" :drawer-name="drawer.conclusionShow.name"></crm-all-show>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import CrmUpdate from "./components/crm-update";
import CrmAllShow from "@/views/doctorCabinet/components/Parts/crm-all-conculations-show";
import CrmCreate from "./components/crm-create";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";
export default {
  mixins: [list, drawer],
  name: "PatientList",
  components: {
    CrmUpdate,
    CrmAllShow,
    CrmCreate,
  },
  data() {
    return {
      drawerCreate: false,
      update_drawer: false,
      reopenShow: false,
      drawerUpdate: false,
      reopenUpdate: false,
      excel_data: [],
      excel_fields: {},
      loadingData: false,
      selected_patient: [],
      drawer: {
        conclusionShow: {
          name: "conclusionShow",
          status: false,
          component: "componentDrawerConclusionShow",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      list: "patients/list",
      columns: "patients/columns",
      pagination: "patients/pagination",
      filter: "patients/filter",
      sort: "patients/sort",
      socials: "socials/list",
      organizations: "organizations/list",
      DocumentTypes: "documentTypes/list",
      states: "states/list",
      cities: "cities/list",
      regions: "regions/list",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async created() { },
  async mounted() {
    this.controlExcelData();
    if (this.socials && this.socials.length === 0) await this.loadSocials();

    if (this.organizations && this.organizations.length === 0)
      await this.loadOrganizations();
    if (this.DocumentTypes && this.DocumentTypes.length === 0)
      await this.loadDocumentTypes();
    if (this.states && this.states.length === 0) await this.loadStates();
    if (this.cities && this.cities.length === 0) await this.loadCities();
    if (this.regions && this.regions.length === 0) await this.loadRegions();
  },
  watch: {
    columns: {
      handler: function () {
        this.controlExcelData();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      updateList: "patients/index",
      updateSort: "patients/updateSort",
      updateFilter: "patients/updateFilter",
      updateColumn: "patients/updateColumn",
      updatePagination: "patients/updatePagination",
      editModel: "patients/show",
      empty: "patients/empty",
      delete: "patients/destroy",
      refreshData: "patients/refreshData",
      loadSocials: "socials/index",
      loadOrganizations: "organizations/index",
      loadDocumentTypes: "documentTypes/index",
      loadStates: "states/index",
      loadCities: "cities/index",
      loadRegions: "regions/index",
    }),
    fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    showConclusion(model) {
      this.drawer.conclusionShow.status = true;
      this.selected_patient = model;
    },
    close_Drawer() {
      this.update_drawer = false;
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => { });
    },
    async edit(model) {
      await this.editModel(model.id)
        .then(async (res) => {
          this.drawerUpdate = true;
          this.update_drawer = true;
        })
        .catch((err) => { });
    },
    async show(model) {
      await this.showModel(model.id)
        .then((res) => {
          this.drawerShow = true;
        })
        .catch((err) => { });
    },
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];
          if (column.show && column.column !== "settings") {
            switch (column.column) {
              case "phone":
                this.excel_fields[column.title] = "phone";
                break;
              default:
                this.excel_fields[column.title] = column.column;
            }
          }
        }
      }
    },
    destroy(model) {
      this.delete(model.id)
        .then((res) => {
          this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>
